import { createI18n } from 'vue-i18n'
import  zh_CN  from '@/locales/zh_CN.js'
import  en_US  from '@/locales/en_US.js'

const i18n = createI18n({
    legacy: false, // 如果你不需要支持 Vue 2 的 legacy 模式
    globalInjection: true,
    fallbackLocale: "zh_CN",
    locale: 'en_US', // 默认语言
    messages: {
      en_US: en_US,
      zh_CN: zh_CN
    },
  });

export default i18n
