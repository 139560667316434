
export default {
        appName:"Suixinliao",
        home:"Home",
        about:"About",
        download:"Download",
        theme:{
            title:"",
            desc:"Chat with friends anytime, anywhere\nExchange voice and video calls"
        },
        model1:{
            title:"Messaging is fast, secure and encrypted",
            desc:"Your privacy is our top priority. With end-to-end encryption, you can ensure your personal messages are visible only to you and the recipient."
        },
        model2:{
            title:"Converse freely",
            desc: "End-to-end encryption makes your personal messages and calls more secure. Only the person you are talking to and you can read or listen to the message content. Beyond that, no one else (even Suixinliao) can There's no way to know.",
            li01:{
                title:"Free",
                desc:"Suixinliao provides free and secure messaging."
            },
            li02:{
                title:"Safety",
                desc:"Xpress Chat protects your messages from hacker attacks."
            },
            li03:{
                title:"Quick",
                desc:"Deliver messages faster than any other app."
            },
            li04:{
                title:"Privacy",
                desc:"Suixinliao adopts end-to-end coding."
            },
            li05:{
                title:"Anti-eavesdropping",
                desc:"Use trunk calling technology to encrypt communications and ensure the security of your IP information."
            },
            li06:{
                title:"Simple",
                desc:"Chat is easy, you already know how to use it."
            }
        },
        model3:{
            title:"Make voice and video calls\nshare each other's wonderful world",
            desc:"Share your wonderful world. Send photos and files, voice and video calls, no matter how big or small. Get grandma's secret recipes in the blink of an eye."
        },
        model4:{
            title:"Use groups to stay connected",
            desc:"Stay connected with the groups you're closest to with simple group and community messaging."
        },
        model5:{
            title:"Download and chat",
            desc: "Use Suixinliao Chat on multiple devices to continue communicating, and the conversation never stops"
        },
        footer:{
            desc:"A cloud-based mobile messaging app focused on security and speed.",
             privacy:"Privacy Terms",
             clientAgreement:"Client Agreement"
        },
        platform:{
            title:"A cloud messaging application",
            subTitle:"Focus on security and speed",
            downBtn:"Download Suixinliao",
            downloading: "Redirecting to download...",
            downloadHint: "Please wait",
            }
    
    
}