import { encryptParams } from "@/utils/util";
import axios from "axios";

export default {  

    //api封装
    async getJSON(method,data) {
        const formData = await encryptParams(method, data);

        return  axios.post("https://openapi.669669.net/?s="+method+"&sign="+formData.sign,formData.data)
        .then(res => {
            if(res.data.ret===200){
                return res.data;
            }else{
                console.log(res.data)
                return null
            }
        })
        .catch(err =>{
            console.log('错误返回 ',err)
            return err;
        })
    }
}