  

    //支持语言
var supportedLanguages= [
    {'name':'简体中文','lang':'zh_CN'},
    {'name':'English','lang':'en_US'},
    /* {'name':'ไทย','lang':'th_TH'},
    {'name':'日本の','lang':'ja_JP'},
    {'name':'한국어','lang':'ko_KR'},
    {'name':'Türk','lang':'tr_TR'},//Turkish 土耳其
    {'name':'español','lang':'es_ES'},//Spanish 西班牙
    {'name':'हिन्दी','lang':'hi_IN'},//印地语
    {'name':'Português(Portugal)','lang':'pt_PT'},//Portuguese 葡萄牙的
    {'name':'français','lang':'fr_FR'},
    {'name':'русский','lang':'ru_RU'},
    {'name':'Deutsch','lang':'de_DE'},//德语            
    {'name':'العربية','lang':'ar_SA'},//阿拉伯 */
  ]

  export {supportedLanguages}
