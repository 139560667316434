import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import md5 from "@/plugins/md5";
import Cookies from "js-cookie";
import { supportedLanguages } from '@/utils/config';

//初始化语言
const  languageInit=async(queryLanguage)=>{
  if(queryLanguage!=undefined&&queryLanguage.indexOf('-')>-1) queryLanguage = queryLanguage.replace('-','_');

  //系统语言
  var sysLanguage = navigator.languages;
  let newLang = sysLanguage[0].indexOf('-')>-1?sysLanguage[0].replace('-','_'):sysLanguage[0];
  const cookieLanguage = Cookies.get('language')|| "";
  if(queryLanguage!=''&&queryLanguage!=undefined&&queryLanguage!=null) {
    newLang = queryLanguage;  
  }else if(cookieLanguage!=''&&cookieLanguage!=undefined){
    newLang = cookieLanguage;  
  }

  //重复语言参数
  if(newLang instanceof Array){
    newLang = newLang[0];
  }
  //支持的语言包
  const idx = supportedLanguages.findIndex((e)=>e.lang.indexOf(newLang))
  if(idx==-1){    
    console.log('语言包不存在')
    newLang = 'en_US';
  }else{
    if(newLang=='zh_HK'||newLang=='zh_TW') newLang = "zh_CN";    
  }

  console.log('系统语言：',sysLanguage,'cookie:',cookieLanguage,'query:',queryLanguage,'语言:',newLang)
  Cookies.set('language',newLang,{ expires: 30, path: "" })
  return newLang;
}

//签名（收银台）
const encryptParams = async(method,reqData) =>{
  const timestamp = Math.round(new Date().getTime()/1000).toString();
  //const serverTime = await user.getServerTime();
  const baseData = {
      "timestamp": Number(timestamp),
      "user": 'h5'
  };

  return signKey(method, baseData, reqData);
}

//签名（资讯api）
const signKey = async(method, baseData, reqData) =>{
    const secretKey = "tg5reFNNpzBB2BKIQHuFPnDEomXoQyVB";
  let keys = [];
  let url='';
  let sign='';
  
  let signData = {...baseData,...reqData};
  signData['s'] = method;

  //升序排序
  for(const key in signData) {
  keys.push(key);
  };
  keys.sort();


  for(let i=0;i<keys.length;i++){
    url += signData[keys[i]];
  }
  sign = md5.hexMd5(url+secretKey); 
  baseData = {...baseData,...reqData};
  baseData['sign'] = sign;

  //转formdata
  const formData = new FormData();
  Object.keys(baseData).forEach((key) => {
    formData.append(key, baseData[key]);
  });

  return {
    sign:sign,
    data:formData
  };
}


//获取url query
const getQueryVariabl=(variable)=>{
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i=0;i<vars.length;i++) {
          let pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
  }
  return(false);
}

/*移动设备判断安卓||IOS*/
const sysPlatform=()=>{
  const isMobile=()=> {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows CE|iPod|Windows Phone|Symbian|Linux/i.test(navigator.userAgent);
  }
  var userAgent = navigator.userAgent.toLowerCase(),
      sys = "";

  if (isMobile) {
      //安卓
      if (userAgent.match(/android/i)) {
          sys = "android";
      }
      //苹果
      if (userAgent.match(/(iphone|ipad|ipod|ios)/i)) {
          sys = "ios";
      }
      return sys;
  } else {
      return false;
  }
}

export {
  languageInit,
  encryptParams,
  getQueryVariabl,
  sysPlatform
};


