
export default{
        appName:"随心聊",
        home:"首页",
        about:"关于",
        download:"下载",
        theme:{
            title:"",
            desc:"随时随地和好友聊天\n互发语音以及视频通话"
        },
        model1:{
            title:"消息传递快速安全且加密",
            desc:"您的隐私是我们的头等大事。借助端到端加密技术，您可以确保您的个人消息只有您和接收人才能看到。"
        },
        model2:{
            title:"自由自在地交谈",
            desc:"端到端加密让你的个人消息和通话更有安全保障。只有与您交谈的人和您自己可以读取或收听消息内容。除此之外，其他任何人（甚至包括Suixinliao）都无法得知。",
            li01:{
                title:"免费",
                desc:"随心聊提供免费且安全消息传递。"
            },
            li02:{
                title:"安全",
                desc:"随心聊保护您的消息描述黑客攻击。"
            },
            li03:{
                title:"快速的",
                desc:"传递消息的速度比任何其他应用程序都快。"
            },
            li04:{
                title:"隐私",
                desc:"随心聊采用端对端加码。"
            },
            li05:{
                title:"防窃听",
                desc:"使用中继线呼叫技术加密通信，确保您的IP信息安全。"
            },
            li06:{
                title:"简单的",
                desc:"随心聊非常简单，您已经知道如何使用它。"
            }
        },
        model3:{
            title:"拨通语音和视频通话\n分享彼此的精彩世界",
            desc:"分享您的精彩世界。 发送照片和文件、语音和视频通话，不限大小。 眨眼间即可获得奶奶的秘方。"
        },
        model4:{
            title:"利用群组，保持联络",
            desc:"通过简单的群组和社群信息，与关系最密切的群组保持联系。"
        },
        model5:{
            title:"下载随心聊",
            desc:"在多个设备使用随心聊持续沟通交流，对话从不间断"
        },
        footer:{
            desc:"一款基于云的移动消息传递应用程序，注重安全性和速度。",
            privacy:"隐私条款",
            clientAgreement:"客户协议"
        },
        platform:{
            title:"一款基于云消息的应用程序",
            subTitle:"注重安全和速度",
            downBtn:"下载随心聊",
            downloading:"正在跳转下载中...",
            downloadHint:"请稍候",
        }
    
}